import { trackEvent } from '@99designs/common/utils/platform';
import Cookies from 'cookies-js';
import './detachedDropDown';
// this type is to avoid the ts error `Property 'expire' does not exist on type 'CookiesStatic<object>'.` in `removeDesignFromCookie`
// as the `expire` method wasn't declared in the `interface CookiesStatic` in assets/node_modules/@types/js-cookie/index.d.ts
type TypeCookiesJS = Cookies.CookiesStatic & {
    expire(key: string, options?: CookieOptions): CookiesStatic;
};

declare global {
    interface Window {
        isUserLoggedIn: boolean;
        inspirationCollector: string;
    }
}

interface DesignImgData {
    designSrc: string;
    designAlt: string;
}

interface InspirationData {
    designId: string;
    designSrc: string;
    designAlt: string;
    designerId: string;
    categoryKey?: string;
    industryKey?: string;
}

export default class LikeableDesign {
    designId: string;
    designerId: string;
    designImgData: DesignImgData;
    likeButton: Element;
    likeCountElement: Element;
    likeAttr: string;
    cookieKey: string;
    categoryKey?: string;
    industryKey?: string;
    inspirationCollectorEnabled?: boolean;

    constructor(
        designId: string,
        designerId: string,
        designImgData: DesignImgData,
        likeButton: Element,
        likeCountElement: Element,
        categoryKey?: string,
        industryKey?: string,
        inspirationCollectorEnabled?: boolean
    ) {
        this.designId = designId;
        this.designerId = designerId;
        this.likeButton = likeButton;
        this.likeCountElement = likeCountElement;
        this.likeAttr = 'data-liked';
        this.cookieKey = 'liked_designs';
        this.likeButton.addEventListener('click', () => {
            this.toggleLike();
        });
        this.categoryKey = categoryKey;
        this.industryKey = industryKey;
        this.designImgData = designImgData;
        this.inspirationCollectorEnabled = inspirationCollectorEnabled;
    }

    initialise() {
        if (this.hasDesignBeenLiked()) {
            this.likeDesign();
        }
    }

    isInspirationCollectorVisible() {
        return window.inspirationCollector === 'enabled' && !window.isUserLoggedIn;
    }

    getMaxDesignsInCollector(): Number {
        return JSON.parse(localStorage.getItem('maxDesignsInCollector') || '10');
    }

    getInspirations(): InspirationData[] {
        return JSON.parse(localStorage.getItem('inspirations') || '[]');
    }

    hasDesignBeenLiked(): boolean {
        const cookie = Cookies.get(this.cookieKey);
        const wasDesignedLiked = !!(cookie && cookie.indexOf(this.designId) !== -1);

        // existing cookie based enablement if feature flag is disabled
        if (!this.isInspirationCollectorVisible()) {
            return wasDesignedLiked;
        }

        if (wasDesignedLiked) {
            // using Interval and checking if collector is loaded, after dispatching event, interval is cleared.
            const dispatchInterval = setInterval(() => {
                const collector = document.getElementById('inspiration-collector');
                if (collector && collector.childNodes && collector.childNodes.length > 0) {
                    this.dispatchLikeUpdated(true);
                    this.removeDesignFromCookie();
                    clearInterval(dispatchInterval);
                }
            }, 1000);

            return true;
        }

        const inspirations = this.getInspirations();

        return (
            inspirations.find((design: InspirationData) => design.designId === this.designId) !==
            undefined
        );
    }

    toggleLike() {
        const inspirations = this.getInspirations();
        const designIdTooltip = document.getElementById(this.designId);
        if (designIdTooltip) {
            designIdTooltip.style.display = 'none';
        }
        if (this.likeButton.hasAttribute(this.likeAttr)) {
            this.processUnlikeAction();
            this.dispatchLikeUpdated(false);
        } else if (
            inspirations.length < this.getMaxDesignsInCollector() ||
            !this.isInspirationCollectorVisible()
        ) {
            this.processLikeAction();
            this.dispatchLikeUpdated(true);
        } else {
            if (designIdTooltip) {
                designIdTooltip.style.display = 'block';
            }
            this.dispatchLikeUpdated(true);
        }
    }

    processLikeAction() {
        this.likeDesign();
        if (!this.isInspirationCollectorVisible()) {
            this.addDesignToCookie();
        }
        this.trackClickEvent(true);
    }

    processUnlikeAction() {
        this.unlikeDesign();
        if (!this.isInspirationCollectorVisible()) {
            this.removeDesignFromCookie();
        }
        this.trackClickEvent(false);
    }

    dispatchLikeUpdated(liked: boolean) {
        if (this.inspirationCollectorEnabled) {
            const { designSrc, designAlt } = this.designImgData;
            window.dispatchEvent(
                new CustomEvent('DesignLikeUpdated', {
                    detail: {
                        liked,
                        designId: this.designId,
                        designSrc,
                        designAlt,
                        designerId: this.designerId,
                        categoryKey: this.categoryKey,
                        industryKey: this.industryKey,
                    },
                })
            );
        }
    }

    likeDesign() {
        this.likeButton.setAttribute(this.likeAttr, '');
        this.likeCountElement.innerHTML = (
            parseInt(this.likeCountElement.innerHTML) + 1
        ).toString();
        if (this.inspirationCollectorEnabled) {
            window.addEventListener(
                'InspirationCollectorUpdated',
                this.onInspirationCollectorUpdate
            );
        }
    }

    onInspirationCollectorUpdate = (e: CustomEvent) => {
        const { liked, designId } = e.detail;
        if (designId === this.designId && !liked) {
            this.processUnlikeAction();
        }
    };

    unlikeDesign() {
        this.likeButton.removeAttribute(this.likeAttr);
        this.likeCountElement.innerHTML = (
            parseInt(this.likeCountElement.innerHTML) - 1
        ).toString();
        if (this.inspirationCollectorEnabled) {
            window.removeEventListener(
                'InspirationCollectorUpdated',
                this.onInspirationCollectorUpdate
            );
        }
    }

    removeDesignFromCookie() {
        const cookie = Cookies.get(this.cookieKey);
        const updatedDesignIds = cookie && cookie.replace(`${this.designId},`, '');

        if (updatedDesignIds) {
            Cookies.set(this.cookieKey, updatedDesignIds);
        } else {
            (Cookies as TypeCookiesJS).expire(this.cookieKey);
        }
    }

    addDesignToCookie() {
        const cookie = Cookies.get(this.cookieKey);

        if (cookie) {
            Cookies.set(this.cookieKey, cookie.concat(`${this.designId},`));
        } else {
            Cookies.set(this.cookieKey, `${this.designId},`);
        }
    }

    trackClickEvent(liked: boolean) {
        trackEvent('Clicked Like Button On Inspiration Page', {
            liked: liked,
        });
    }

    static create(design: HTMLElement, inspirationCollectorEnabled: boolean) {
        const designId = design.getAttribute('data-likeable-design');
        const designerId = design.getAttribute('data-likeable-design-designer_id');
        const categoryKey = design.getAttribute('data-likeable-design-category_key');
        const industryKey = design.getAttribute('data-likeable-design-industry_key');
        const likeButton = design.querySelector('[data-likeable-design-like-button]');
        const likeCountElement = design.querySelector('[data-likeable-design-like-count]');

        const designImg = design.querySelector('img.design-card__image');
        const designImgData: DesignImgData = {
            designSrc: (designImg && designImg.getAttribute('data-src')) || '',
            designAlt: (designImg && designImg.getAttribute('alt')) || '',
        };

        if (!designId || !designerId || !likeButton || !likeCountElement) return;

        const likeableDesign = new LikeableDesign(
            designId,
            designerId,
            designImgData,
            likeButton,
            likeCountElement,
            categoryKey ? categoryKey : '',
            industryKey ? industryKey : '',
            inspirationCollectorEnabled
        );

        likeableDesign.initialise();
    }
}
