import { getOffset } from 'common/utils/boxPosition';
// tslint:disable-next-line: import-blacklist
import $ from 'jquery';

export function scrollToElementWithSpeed(element: Element, offsetTop = 0, speed = 1700) {
    const documentTop = $(document).scrollTop() || 0;
    const targetTop = getOffset(element).top - offsetTop;
    const distance = targetTop - documentTop;
    const duration = (distance / speed) * 1000;

    $('html, body').animate(
        {
            scrollTop: targetTop,
        },
        duration
    );
}

export function scrollToElement(
    element: Element,
    offsetTop = 0,
    duration = 500,
    callback = () => {}
) {
    $('html, body')
        .animate(
            {
                scrollTop: getOffset(element).top - offsetTop,
            },
            duration
        )
        .promise()
        .then(callback);
}

export function scrollDownToElement(element: Element, offsetTop = 0, duration = 500) {
    if (isElementAboveDocumentScrollTop(element, offsetTop)) {
        scrollToElement(element, offsetTop, duration);
    }
}

function isElementAboveDocumentScrollTop(element: Element, offsetTop: number) {
    const documentScrollTop =
        window.pageYOffset || (document.documentElement && document.documentElement.scrollTop) || 0;

    return getOffset(element).top - offsetTop - documentScrollTop >= 0;
}
