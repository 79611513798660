import '@99designs/common/jquery-plugins/tooltip';

import { trackEvent, trackLink } from '@99designs/common/utils/platform';
import Bugsnag from 'common/utils/Bugsnag';
import debounce from 'common/utils/debounce';
import { scrollToElement } from 'common/utils/scroll';
import { get } from 'common/utils/xhr';
import 'lazysizes';
import shave from 'shave';
import EmailCaptureForm from './components/EmailCaptureForm';
import LikeableDesign from './components/LikeableDesign';
import { showFlashSaleElements } from './flashSale';

declare const window: any;

document.addEventListener('DOMContentLoaded', () => {
    const inspirationCollectorEnabled = window.inspirationCollector === 'enabled';
    initDesignCards();
    shave('[data-ellipsize]', 100);
    initLikeableDesigns(inspirationCollectorEnabled);
    initAttributionPopups();
    initShowMoreItems();
    initSearchInspiration();
    initSubscribeCTA();
    initEmailCaptureForm();
    showFlashSaleElements();
    if (inspirationCollectorEnabled) {
        initInspirationCollector();
    }
    initLogoMakerTracking();
    initVistaXWixTracking();
    // @ts-ignore
    jQuery('[data-tooltip]').tooltip({ location: 'up-center' });
});

function initEmailCaptureForm() {
    const emailCollectorForm = document.querySelector<HTMLElement>('[data-email-collector]');

    if (!emailCollectorForm) {
        return;
    }

    // tslint:disable-next-line:no-unused-expression
    new EmailCaptureForm(emailCollectorForm, {
        withRecaptcha: true,
        emailFieldName: 'email_address',
    });
}

function initDesignCards() {
    const designCards = document.querySelectorAll<HTMLElement>('[data-design-card]');
    for (var i = 0; i < designCards.length; i++) {
        designCards[i].style.visibility = 'visible';
    }
}

function initLikeableDesigns(inspirationCollectorEnabled: boolean) {
    const designs = Array.from(document.querySelectorAll<HTMLElement>('[data-likeable-design]'));

    designs.forEach(design => {
        LikeableDesign.create(design, inspirationCollectorEnabled);
    });
}

function initAttributionPopups() {
    const attributions = Array.from(
        document.querySelectorAll<HTMLElement>('[data-attribution-popup]')
    );

    if (attributions.length === 0) return;

    if (typeof window.mountPopupCard === 'function') {
        window.mountPopupCard(attributions, 'inspiration');
    } else {
        Bugsnag.notify('Failed to load Attribution Popup microfrontend');
    }
}

function initInspirationCollector() {
    if (typeof window.mountInspirationCollector === 'function') {
        window.mountInspirationCollector('inspiration-collector', {
            isUserLoggedIn: window.isUserLoggedIn,
        });
    } else {
        Bugsnag.notify(
            new Error('Inspiration Collector microfrontend failed to mount in Start launch flow')
        );
    }
}

function initShowMoreItems() {
    const buttonTrigger = document.querySelector<HTMLElement>('[data-show-more-items-trigger]');
    const endOfScrollCTA = document.querySelector<HTMLElement>('[data-end-of-scroll-cta]');
    const maxNumOfDesignsPerTrigger = 6;

    if (!buttonTrigger || !endOfScrollCTA) {
        return;
    }

    endOfScrollCTA.setAttribute('data-hidden', '');

    buttonTrigger.addEventListener('click', () => {
        const nextItems = hiddenMatrixItems().slice(0, maxNumOfDesignsPerTrigger);
        if (nextItems.length > 0) {
            nextItems.forEach(item => item.removeAttribute('data-show-more-items-hidden'));
            trackEvent('Clicked CTA On Inspiration Page', {
                location: 'show-more-design-cards',
            });
        }

        if (hiddenMatrixItems().length === 0) {
            buttonTrigger.setAttribute('data-hidden', '');
            endOfScrollCTA.removeAttribute('data-hidden');
        }

        shave('[data-ellipsize]', 100);
    });
}

function hiddenMatrixItems() {
    return Array.from(document.querySelectorAll('[data-show-more-items-hidden]'));
}

function initSubscribeCTA() {
    const subscribeCTA = document.querySelector<HTMLElement>('[data-subscribe-cta]');

    if (!subscribeCTA) {
        return;
    }

    subscribeCTA.addEventListener('click', event => {
        event.preventDefault();
        const emailForm = document.querySelector('[data-email-collector]');
        if (emailForm) {
            scrollToElement(emailForm, 150, 1000);
        }
    });
}

function initSearchInspiration() {
    function createSearchResultListItem(label: string): HTMLLIElement {
        const li = document.createElement('li');
        li.setAttribute('class', 'tag-suggestions__item tag-suggestions__item--new');
        li.setAttribute('data-tag-suggestions-item', '');
        li.setAttribute('data-tag-suggestions-item-default', '');

        const span = document.createElement('span');
        span.setAttribute('data-tag-suggestions-item-name', '');
        span.textContent = label;
        li.appendChild(span);
        return li;
    }

    function bindSearchResultListItemHandler(element: HTMLLIElement, location: string) {
        element.addEventListener('click', event => {
            const selectedValue = (event.target as HTMLElement).innerText.trim();
            trackEvent('Selected Search Result', {
                location: location,
                search_term: selectedValue,
            });
            window.open(`/discover?tags=${selectedValue}`, '_blank').focus();
        });
    }

    function doSearch(
        searchInputField: HTMLInputElement,
        searchResultsList: HTMLUListElement,
        location: string
    ) {
        const searchValue = searchInputField.value;

        // hide the search results when the user removes the text.
        if (searchValue == '') {
            searchResultsList.removeAttribute('data-active');
            return;
        }

        trackEvent('Performed Search', {
            location: location,
            searchTerm: searchValue,
        });

        get(`/discover/design_tags/completions?noredirect=once&query=${searchValue}`).end(
            (error, res) => {
                if (error) {
                    return;
                }

                // remove previously appended child nodes;
                while (searchResultsList.hasChildNodes()) {
                    searchResultsList.removeChild(searchResultsList.childNodes[0]);
                }

                const searchResults = res.body as string[];

                // check if search value does not exist in array then append the searchvalue at the top
                if (!searchResults.includes(searchValue)) {
                    searchResults.splice(0, 0, searchValue);
                }

                searchResults.forEach(searchResult => {
                    const listItem = createSearchResultListItem(searchResult);
                    bindSearchResultListItemHandler(listItem, location);
                    searchResultsList.appendChild(listItem);
                });

                searchResultsList.setAttribute('data-active', '');
            }
        );
    }

    const searchElements = document.querySelectorAll<HTMLElement>('[data-inspiration-searcher]');
    searchElements.forEach(searchElement => {
        const location =
            searchElement.getAttribute('data-inspiration-searcher-location') || 'inspiration';
        const searchInputField = searchElement.querySelector<HTMLInputElement>(
            '[data-search-input]'
        );
        const searchResultsList = searchElement.querySelector<HTMLUListElement>(
            '[data-tag-suggestions-list]'
        );
        if (!searchInputField || !searchResultsList) {
            return;
        }
        searchInputField.addEventListener(
            'keyup',
            debounce(() => doSearch(searchInputField, searchResultsList, location), 300)
        );
    });
}

function initLogoMakerTracking() {
    const logomakerCTA = Array.from(document.querySelectorAll('[data-logomaker-cta]'));
    logomakerCTA.forEach(cta => {
        const location = cta.getAttribute('data-cta-location');
        trackLink(cta, 'Vistaprint Logomaker Link Clicked', {
            location,
        });
    });
}

function initVistaXWixTracking() {
    const vistaXWixCTA = Array.from(document.querySelectorAll('[data-vista-x-wix-cta]'));
    vistaXWixCTA.forEach(cta => {
        const location = cta.getAttribute('data-cta-location');
        trackLink(cta, 'Vista x Wix Link Clicked', {
            location,
        });
    });
}
