import request from 'superagent'; // tslint:disable-line:import-blacklist

export function refreshCsrfToken() {
    return new Promise((resolve, reject) => {
        get('/start/csrf-meta').end((err, res) => {
            if (err) {
                return reject(err);
            }

            const { body } = res;
            let meta = document.querySelector(`meta[name="${body.param}"]`) as HTMLMetaElement;

            if (meta) {
                meta.content = body.token;
            } else {
                meta = document.createElement('meta');
                meta.name = body.param;
                meta.content = body.token;
                (document.querySelector('head') as HTMLHeadElement).appendChild(meta);
            }

            return resolve();
        });
    });
}

export function csrfToken() {
    const token = document.querySelector('meta[name="csrf-token"]') as HTMLMetaElement;
    if (token) {
        return token.content;
    } else {
        return '';
    }
}

export function post(url: string, data: any) {
    return request
        .post(url)
        .send(data)
        .set('Accept', 'application/json')
        .set('X-CSRF-Token', csrfToken());
}

export function get(url: string) {
    return request.get(url).set('Accept', 'application/json');
}
