import EmailSubscriptionService from '@99designs/common/utils/platform/emailSubscriptionService';
import { scrollToElement } from 'common/utils/scroll';
import serialize from 'form-serialize';

declare const grecaptcha: any;

const RECAPTCHA_VERFIED_ENDPOINT = '/campaigns/xhr/email-subscriptions/subscribe';

type Config = {
    withRecaptcha?: boolean;
    emailFieldName?: string;
    formName?: string;
};

class EmailCaptureForm {
    private container: HTMLElement;
    private service: EmailSubscriptionService;
    private config: Config;

    constructor(container: HTMLElement, config?: Config) {
        this.container = container;
        this.service = new EmailSubscriptionService();
        this.container.addEventListener('submit', this.handleSubmit.bind(this));
        this.config = config || {};
    }

    getEndpoint() {
        if (this.config.withRecaptcha) {
            return RECAPTCHA_VERFIED_ENDPOINT;
        }

        // See EmailSubscriptionService in FEC for default endpoint
        return;
    }

    getEmailFieldName() {
        return this.config.emailFieldName || 'email';
    }

    handleSubmit(e: Event) {
        e.preventDefault();

        if (this.config.withRecaptcha) {
            const recaptchaResponse = grecaptcha.getResponse();

            if (!recaptchaResponse) {
                // Has not successfully completed the recaptcha
                return false;
            }
        }

        const formName: string | undefined =
            this.config.formName || this.container.dataset.emailCaptureForm;
        const form = <HTMLFormElement>e.target;
        const formSerialized = serialize(form, { hash: true });
        const formData: any = formName ? formSerialized[formName] : formSerialized;
        const { [this.getEmailFieldName()]: email, ...customData } = formData;
        const acquisitionSource = <string>formData['acquisition_source'];
        const subscribeToMarketing = <string>formData['subscribe_to_marketing'] === 'true';
        customData['recaptcha_response'] = formSerialized['g-recaptcha-response'];

        this.setProcessingState(form);

        if (!email) {
            return;
        }

        this.service
            .subscribe({
                email: email,
                acquisitionSource: acquisitionSource,
                customData: customData,
                subscribeToMarketing: subscribeToMarketing,
                emailSubscriptionEndpoint: this.getEndpoint(),
            })
            .then(() => {
                const form = this.container.querySelector<HTMLFormElement>('[data-form]');

                if (form) {
                    // Hide form
                    form.setAttribute('data-hidden', 'true');
                    this.unsetProcessingState(form);
                }

                const successMessage = this.container.querySelector('[data-form-success]');
                if (successMessage) {
                    // Show form success message
                    successMessage.removeAttribute('data-hidden');
                }

                scrollToElement(this.container, 30, 0);
            })
            .catch(() => {
                this.unsetProcessingState(form);
            });
    }

    setProcessingState(form: HTMLFormElement) {
        const fields = Array.from(
            form.querySelectorAll<HTMLInputElement>('input, textarea, .select')
        );
        const submitButton = <HTMLButtonElement>form.querySelector('button[type="submit"]');

        if (submitButton) {
            submitButton.setAttribute('data-disabled', 'true');
            submitButton.disabled = true;
            submitButton.classList.add('button--loading');
        }

        for (const field of fields) {
            field.setAttribute('data-disabled', 'true');
            field.disabled = true;

            if (field.querySelector('select')) {
                const select = field.querySelector('select');
                if (select) {
                    select.setAttribute('data-disabled', 'true');
                    select.disabled = true;
                }
            }
        }
    }

    unsetProcessingState(form: HTMLFormElement) {
        const fields = Array.from(
            form.querySelectorAll<HTMLInputElement>('input, textarea, .select')
        );
        const submitButton = <HTMLButtonElement>form.querySelector('button[type="submit"]');

        if (submitButton) {
            submitButton.removeAttribute('data-disabled');
            submitButton.disabled = false;
            submitButton.classList.remove('button--loading');
        }

        for (const field of fields) {
            field.removeAttribute('data-disabled');
            field.disabled = false;

            if (field.querySelector('select')) {
                const select = field.querySelector('select');
                if (select) {
                    select.removeAttribute('data-disabled');
                }

                field.disabled = false;
            }
        }
    }
}

export default EmailCaptureForm;
